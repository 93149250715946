<template>
    <div :class="$style['plotsLine']">
      <div
        :class="$style['plotsList']"
        v-on="$listeners"
      >
        <PlotsLineSlider
          :plots="plots"
          @sendNewReachGoal="sendNewReachGoal($event)"
        />
      </div>
    </div>
  </template>
  <script>
  import { guardUnspecified, guardEmptyArray } from '@portal/utils/util-guards';
  import { StoreMixin } from '../../../shared/mixins/';
  import PlotsLineSlider from './plots-line-slider';
  
  export default {
    name: 'PlotsLine',
    components: {
      PlotsLineSlider,
    },
    mixins: [StoreMixin],
    props: {
      plots: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    methods: {
      sendNewReachGoal(valueName) {
        this.store.analyticsModule.sendNewReachGoal({
          blockType: 'Меню',
          fieldType: 'Строка сюжетов',
          valueName,
          goalName: 'clickMenu',
          willDeprecate: true
        });
  
        this.store.analyticsModule.sendNewFormatReachGoal({
          goalName: 'ClickMenuThemes',
          blockType: 'Меню',
          actionType: 'Клик',
          prop1: 'Сюжеты',
          prop2: valueName
        });
      },
      closeAlerts() {
        this.store.commonModule.updateAlertsState({
          value: 'close',
          changedBy: 'plots-line'
        });
      }
    }
  };
  </script>
  <style lang="scss" module>
  @use '~jtn-ui/z-index';
  
  @import 'styles';
  
  .plotsLine {
    position: relative;
    z-index: z-index.$page--top-info;
  
    padding: 0 $mobile-padding;
  
    background: $color-surface;
  
    @include on-min-resize($tablet) {
      padding: 0 $desktop-padding;
    }
  }

  .plotsList {
    display: flex;
    align-items: center;

    width: 100%;
    height: 56px;
    padding: 0;
  }
  </style>
  