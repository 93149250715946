<template>
  <div ref="wrapper" :class="$style.plotsLineSlider">

    <div v-show="showLeftOverlay" :class="[$style.overlay, $style.overlayLeft]" />

    <UiScrollable
      ref="list"
      tag="div"
      :class="[
        $style['wrapper'],
        hasScrolled ? '' : $style['scrollHidden']
      ]"
      @onScrolled="() => wrapperScroll()"
    >
      <ul :class="$style.list">
        <Plot
          v-for="(plot, index) of plots"
          :key="`${plot.url}_${index}`"
          :plot="plot"
          :isMobile="isMobile"
          @clickAdvMarker="clickAdvMarker(plot)"
          @linkClicked="$emit('sendNewReachGoal', $event)"
        />
      </ul>
    </UiScrollable>

    <div
      v-if="isShowAdvPopup"
      :class="$style.advPopupArrow"
      :style="advPopupArrowStyle"
    />

    <JtnUiTypography
      v-if="isShowAdvPopup"
      ref="advPopup"
      :class="$style.advPopup"
      :style="advPopupStyle"
      :type="`font-photoday-type-1`"
      data-informer=""
    >
      <div v-html="informer" />
      <svg width="10" height="10" :class="$style.closeIcon" @click="closeAdvPopup()">
        <use xlink:href="jtn-critical-close"></use>
      </svg>
    </JtnUiTypography>

    <div v-show="!hideRightScroll" :class="[$style.overlay, $style.overlayRight]" />

    <svg
      v-if="!showLeftOverlay && !hideRightScroll"
      :class="$style.arrow"
      :stroke-width="2"
      :width="18"
      :height="18">
        <use xlink:href="jtn-critical-arrow-right"></use>
    </svg>
  </div>
</template>

<script>
  import { JtnUiTypography } from '@jtnews/jtn-ui';
  import { UiScrollable } from '@jtnews/shared/ui';
  import Plot from './plot/plot';
  
  import { StoreMixin } from '../../../shared/mixins';
  
  export default {
    name: 'PlotsLineSlider',
    components: {
      JtnUiTypography,
      Plot,
      UiScrollable
    },
    mixins: [StoreMixin],
    props: {
      plots: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        wrapperScrollLeft: 0,
        wrapperClientWidth: 0,
        wrapperScrollWidth: 1,
        hasScrolled: false,
        isShowAdvPopup: false,
        informer: '',
        advPopupLeft: 0,
        advPopupArrowLeft: 0,
      };
    },
    computed: {
      isMobile() {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      },
      showLeftOverlay() {
        return this.wrapperScrollLeft > 5;
      },
      hideRightScroll() {
        return (
            this.wrapperClientWidth + this.wrapperScrollLeft >= this.wrapperScrollWidth ||
            this.wrapperClientWidth === this.wrapperScrollWidth
        );
      },
      advPopupStyle() {
        return `left: ${this.advPopupLeft}px;`;
      },
      advPopupArrowStyle() {
        return `left: ${this.advPopupArrowLeft}px;`;
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.setScrollParams();
      });

      document.addEventListener('click', this.handleDocumentClick);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleDocumentClick);
    },
    methods: {
      setScrollParams() {
        this.wrapperScrollLeft = this.$refs.list.$el.scrollLeft;
        this.wrapperClientWidth = this.$refs.list.$el.clientWidth;
        this.wrapperScrollWidth = this.$refs.list.$el.scrollWidth;
      },
      wrapperScroll() {
        this.hasScrolled = true;
        this.setScrollParams();
  
        this.isShowAdvPopup = false;
      },
      clickAdvMarker(plot) {
        if(!plot.informer) {
          return;
        }

        this.isShowAdvPopup = true;
        this.informer = plot.informer;
  
        const targetInfo = event.target?.getBoundingClientRect();
        const wrapperInfo = this.$refs.wrapper?.getBoundingClientRect();
        this.$nextTick(() => {
          const popupInfo = this.$refs.advPopup?.$el.getBoundingClientRect();
  
          this.advPopupArrowLeft = targetInfo?.left + targetInfo?.width/2 - wrapperInfo?.left;
  
          if (window.innerWidth <= popupInfo.width) {
            return false;
          }
  
          this.advPopupLeft = (targetInfo.left + (targetInfo.width - popupInfo.width)/2) - wrapperInfo.left;
          if (this.advPopupLeft + popupInfo.width > window.innerWidth) {
            this.advPopupLeft = window.innerWidth - popupInfo.width;
          }
          if (this.advPopupLeft < 0) {
            this.advPopupLeft = 0;
          }
        });
      },
      closeAdvPopup() {
        this.isShowAdvPopup = false;
      },
      handleDocumentClick(e) {
        const target = e.target;

        if (!target.matches('[data-click-area]') && !target.closest('[data-informer]')) {
          this.closeAdvPopup();
        }
      }
    }
  };
</script>

<style lang="scss" module>
  @use '~jtn-ui/z-index';
  
  @import 'styles';
  
  $shadow-color: #1a1b1d;
  
  .plotsLineSlider {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  
    width: 100%;
    padding: 0;
  }
  
  .wrapper {
    position: relative;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  
    width: 100%;
    height: 100%;
    padding: 13px 0 0;
  
    transform: translate(0, 0);

    @include on-min-resize($laptop) {
      padding: 0;
    }
  
    &.scrollHidden {
      @include scrollbar-hidden(x);
    }
  }
  
  .list {
    @include ul-clear;
  
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  
    max-width: 100%;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    z-index: z-index.$above;
  
    width: 27px;
    height: 100%;
  
    &.overlayLeft {
      left: 0;
  
      background: linear-gradient(90deg, #fff 7.51%, rgba(255, 255, 255, 0) 100%);
    }
  
    &.overlayRight {
      right: 0;
  
      background: linear-gradient(270deg, #fff 7.51%, rgba(255, 255, 255, 0) 100%);
    }
  }
  
  .arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: z-index.$above;
  
    margin: auto;
  
    color: $color-surface-400;
  }
  
  .advPopup {
    position: absolute;
    z-index: 10;
    top: 56px;
    width: max-content;
    max-width: 100%;
    margin-left: -12px;
    padding: 16px 40px 16px 16px;
    background-color: $color-surface;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px #ccc;
    word-wrap: break-word;
  
    color: $color-surface-600;
    cursor: pointer;
  
    @media screen and (min-width: 380px) {
      max-width: 380px;
    }
  }
  
  .advPopupArrow {
    position: absolute;
    z-index: 99;
    top: 56px;
  
    &::after {
      content: "";
      position: absolute;
      left: calc(50% - 6px);
      top: -10px;
      border: 6px solid transparent;
      border-bottom: 6px solid $color-surface;
    }
  }
  
  .closeIcon {
    position: absolute;
  
    top: 10px;
    right: 10px;
  
    color: $color-surface-400;
  }
</style>
