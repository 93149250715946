import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin, tsStoreMixin, Logo } from '@jtnews/shared';

import { AgeLimit } from '../../components/age-limit';

import styles from './longread-footer.style.scss?module';

const SGP_ICON_WIDTH = 200;
const SGP_ICON_HEIGHT = 38;

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'LongreadFooter',
    computed: {
      logoTitle(): string {
        return this.store.commonModule?.regionLogo?.title ?? '';
      },
      sgpLink(): string {
        return this.store.commonModule?.projectLogos?.sgp ?? '';
      }
    },
    render() {
      return (
        <div class={styles.longreadFooter}>
          <Logo class={styles.logo} hasLink={true} />
          <a href={this.sgpLink} target={'_blank'}>
            <svg class={styles.sgpLogo} width={SGP_ICON_WIDTH} height={SGP_ICON_HEIGHT}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-logos.9885e041455146d2e6c16cfb319d9ee9.svg#jtn-logos-sgp"></use>
            </svg>
          </a>
          <AgeLimit />
        </div>
      );
    }
  });
