/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-lines */
import { LoyaltyService } from '@portal/user-loyalty';
import { guardUnspecified, guardEmptyString } from '@portal/utils/util-guards';
import { Regions, isRegionIdMatchedWithGeoId, isSameRegion } from '@smh/projects/regions';
import { localStorageApiFactory } from '@smh/utils/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { Action, Getter, Mutation, State } from 'vuex-simple';

import { getBlockWithParams } from '@jtnews/shared';
import { PagesType } from '@jtnews/shared/data';
import {
  getResponsiveImageData,
  ImageAspectRatio,
  ImageSourceVM,
  ImageDTO
} from '@jtnews/shared/images';
import { UserInfo } from '@jtnews/shared/news';

import { AlertsService, alertsService } from '../services/alerts.service';
import type { AlertsState } from '../services/alerts.service';
import { AuthDataMapper } from '../services/auth';
import { CaptchaService, GReCaptchaService, HCaptchaService } from '../services/captcha';
import {
  ExternalScriptsService,
  externalScriptsService
} from '../services/external-scripts.service';
import { HeaderService, headerService } from '../services/header/header.service';
import { CommonMapper } from '../services/mappers/common-mapper.service';
import type {
  ThemesBlock,
  PictureOfDayBlock
} from '../services/mappers/common-mapper.service';
import { MenuService, menuService } from '../services/menu.service';
import type { MenuState } from '../services/menu.service';
import { PerformanceMetricsService } from '../services/performance-metrics/performance-metrics.service';
import { SearchBarService, searchBarService } from '../services/search-bar.service';
import { UserForumsService, userForumsService } from '../services/user-forums.service';
import type { UserForumsState } from '../services/user-forums.service';
import { UserMenuService, userMenuService } from '../services/user-menu.service';
import type { UserMenuState } from '../services/user-menu.service';
import {
  UserModerationStatusService,
  userModerationStatusService
} from '../services/user-moderation-status';

import { RootModule } from './store';

type ForumMenuItem = {
  id: string;
  name: string;
  link: string;
  count: number;
};

type ForumProfileData = {
  id: number;
  passportId: number;
  login: string;
  link: string;
  email: string;
  avatarData: {
    src: string;
    sources: ImageSourceVM[];
  };
  sex: string;
  menu: ForumMenuItem[];
} | null;

type StickyHeaderState = {
  sticky: boolean;
  stickyHeaderHeight: number;
};

type SearchBarState = 'close' | 'open';

type AuthUrls = Record<string, string>;

type ProjectLogo = {
  auto: string;
  n1: string;
  sgp: string;
  zp: string;
};

type Logo = {
  title: string;
};

type AuthInfo = {
  change: string;
  logout: string;
  register: string;
  recover: string;
  mailRegister: string;
  rules: string;
  mailru?: string;
  twitter?: string;
  vk?: string;
  fb?: string;
  ok?: string;
  google?: string;
  ya?: string;
};

type UserInfoDataFromProfile = {
  avatar: string;
  nick: string;
  sex: string;
  trusted: boolean;
};

type OneSignalParams = {
  appId: string;
  segment: string;
};

type GAParams = {
  category?: string;
  action?: string;
  label?: string;
  value?: number;
};

type ReachGoal = {
  name: string;
  params?: ValueParams;
};

// eslint-disable-next-line
interface GoalValueParams {
  [prop: string]: Record<string, GoalValueParams> | string;
}

type ValueParams = string | number | string[] | Record<string, string | GoalValueParams>;

type CaptchaSettings = {
  publicKey: string;
  scriptSrc: string;
  privacyPolicyText: string;
  type: 'recaptcha' | 'hcaptcha';
};

type MobileBlock = {
  data: {
    link: string;
    name: string;
  }[];
  params: { title: string };
  path: string;
};

type SocialBlock = {
  data: {
    link: string;
    name: string;
  }[];
  params: { title: string };
  path: string;
};

type CurrencyBlock = {
  data: {
    link: string;
    currency: {
      delta: number;
      name: string;
      nominal: number;
      value: number;
    }[];
  };
};

type TrafficBlock = {
  data: {
    hint: string;
    icon: string;
    level: number;
    link: string;
  };
};

type WeatherBlockData = {
  data: {
    city: string;
    ['city_prepositional']: string;
    cloud: {
      title: string;
      value: string;
    };
    humidity: number;
    icon: string;
    more: string;
    precipitation: {
      title: string;
      value: string;
    };
    pressure: number;
    temperature: number;
    ['temperature_feel_like']: number;
    ['temperature_trend']: number;
    wind: {
      direction: {
        title: string;
        ['title_letter']: string;
        ['title_short']: string;
      };
      speed: number;
    };
  };
};

type LoyaltyRole = 'Disloyal' | 'Average' | 'Loyal';

type PlotsLineItem = {
  title: string;
  informer: string;
  url: string;
  type: string;
  template: 'STANDARD' | 'FULL';
  images?: PlotsLineItemImages | undefined;
};

type PlotsLineItemImages = {
  mobileCover?: ImageDTO;
  desktopCover?: ImageDTO;
  mobileMainCover?: ImageDTO;
};

type PlotsLineBlock = {
  data: {
    plots: PlotsLineItem[];
  };
};

export class CommonModule {
  public configName = 'common';

  public headerService: HeaderService = headerService;

  public searchBarService: SearchBarService = searchBarService;

  public alertsService: AlertsService = alertsService;

  public menuService: MenuService = menuService;

  public userMenuService: UserMenuService = userMenuService;

  public userForumsService: UserForumsService = userForumsService;

  public externalScriptsService: ExternalScriptsService = externalScriptsService;

  public userModerationStatusService: UserModerationStatusService =
    userModerationStatusService;

  @State()
  public authUserChecked = false;
  @State()
  public authForumChecked = false;
  @State()
  // Костыль из-за особенностей хендлера коллбэков у викео (добавляет подписки в глобальный объект)
  public viqeoCallbacksLoaded = false;
  @State()
  public userInfo: UserInfo | null = null;
  @State()
  public forumProfileData: ForumProfileData = null;
  @State()
  public userUid = '';
  @State()
  public newProfileNotices = 0;
  @State()
  public newMailsCount = 0;
  @State()
  public hasSpNotify = false;
  @State()
  public loyaltySegment: LoyaltyRole | null = null;

  // eslint-disable-next-line
  private _authDataMapper: AuthDataMapper;

  // eslint-disable-next-line
  private _performanceMetricsService: PerformanceMetricsService;

  // eslint-disable-next-line
  private _hasSubscribeUserLockStatus = false;

  // eslint-disable-next-line
  private _captchaService: CaptchaService;

  // eslint-disable-next-line
  constructor(private _root: RootModule) {}

  public init(): void {
    this._authDataMapper = new AuthDataMapper(this._root.envType || '');
    this._performanceMetricsService = new PerformanceMetricsService();
    this._sendReachGoalUserRegion();
    this._initLoyalty();
    this.initCaptchaService();
  }

  @Getter()
  public get headerServiceState$(): Observable<StickyHeaderState> {
    return this.headerService.state;
  }

  @Getter()
  public get userForumsState$(): BehaviorSubject<UserForumsState> {
    return this.userForumsService.state;
  }

  @Getter()
  public get searchBarState$(): BehaviorSubject<SearchBarState> {
    return this.searchBarService.state;
  }

  @Getter()
  public get menuState$(): BehaviorSubject<MenuState> {
    return this.menuService.state;
  }

  @Getter()
  public get userMenuState$(): BehaviorSubject<UserMenuState> {
    return this.userMenuService.state;
  }

  @Getter()
  public get alertsState$(): BehaviorSubject<AlertsState> {
    return this.alertsService.state;
  }

  @Getter()
  public get alertsClosingAllowed$(): BehaviorSubject<boolean> {
    return this.alertsService.сlosingAllowed;
  }

  @Getter()
  public get alertsReadingStatus$(): BehaviorSubject<boolean> {
    return this.alertsService.readingStatus;
  }

  @Getter()
  public get isBlockedUser(): boolean {
    return this.userInfo?.isBlocked ?? false;
  }

  @Getter()
  public get isTrustedUser(): boolean {
    return this.userInfo?.isTrusted ?? false;
  }

  @Getter()
  public get hasUserNotificationsFeature(): boolean {
    return this.userInfo?.featureFlags?.pushCommentsNewAnswerFeatureFlag ?? false;
  }

  @Getter()
  public get commonThemesBlock(): ThemesBlock {
    return CommonMapper.themesToDO(this._root.data);
  }

  @Getter()
  public get commonPlotsLineBlock(): PlotsLineBlock | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'plot_lines'
    );
  }

  @Getter()
  public get commonReachGoal(): { data: ReachGoal[] } | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'reach_goal'
    );
  }

  @Getter()
  public get commonGAEvents(): { data: GAParams[] } | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'ga_events'
    );
  }

  @Getter()
  public get commonMobileAppsBlock(): MobileBlock | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'mobile_apps'
    );
  }

  @Getter()
  public get captchaSettings(): CaptchaSettings | null {
    return CommonMapper.captchaSettingsToDO(this._root.data);
  }

  @Getter()
  public get captchaText(): string {
    return guardUnspecified(this.captchaSettings)
      ? this.captchaSettings.privacyPolicyText
      : '';
  }

  @Getter()
  public get commonSocialsBlock(): SocialBlock | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'socials'
    );
  }

  @Getter()
  public get commonSpBlock(): unknown {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'sp'
    );
  }

  @Getter()
  public get regionLogo(): Logo | null {
    const result = getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'region_logotype'
    ) as { data: Logo };

    return result?.data ?? null;
  }

  @Getter()
  public get projectLogos(): ProjectLogo | null {
    const result = getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'project_logotypes'
    ) as { data: ProjectLogo };

    return result?.data ?? null;
  }

  @Getter()
  public get commonCurrencyBlock(): CurrencyBlock | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'currency_service'
    );
  }

  @Getter()
  public get commonTrafficBlock(): TrafficBlock | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'traffic'
    );
  }

  @Getter()
  public get commonWeatherBlock(): WeatherBlockData {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'weather'
    );
  }

  @Getter()
  public get commonCommentComplaintsBlock():
    | { data: { id: number; name: string }[] }
    | undefined {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'comment_complaints_reasons'
    );
  }

  @Getter()
  public get oneSignalConfig(): OneSignalParams | null {
    const result = getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'onesignal_config'
    ) as { data: OneSignalParams };

    return result?.data ?? null;
  }

  @Getter()
  public get menuAuthBlock(): { settings: { type: string }; data: AuthInfo } {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'auth'
    );
  }

  @Getter()
  public get generalAuthInfo(): AuthInfo | null {
    return this.menuAuthBlock?.data ?? null;
  }

  @Getter()
  public get authUrls(): AuthUrls {
    const authInfo = this.generalAuthInfo;
    const authUrls: Record<string, string> = {};

    if (guardUnspecified(authInfo)) {
      Object.keys(authInfo).forEach(key => {
        if (guardUnspecified(authInfo[key]) && typeof window !== 'undefined') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          authUrls[key] = authInfo[key].replace('%CURRENT_URL%', window.location.href);
        }
      });
    }

    return authUrls;
  }

  @Getter()
  public get authChecked(): boolean {
    if (isSameRegion(this._root.regionId, Regions.NizhnyNovgorod)) {
      return this.authUserChecked && this.authForumChecked;
    }

    return this.authUserChecked;
  }

  @Getter()
  public get logo(): Logo | null {
    const logoBlock = getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'logo'
    ) as { data: Logo };

    return logoBlock?.data ?? null;
  }

  @Getter()
  public get pictureOfDay(): PictureOfDayBlock {
    return CommonMapper.pictureOfDayToDO(this._root.data);
  }

  @Getter()
  public get isPictureRedesign(): boolean {
    return this._root.settings.picture_of_the_day_redesign ?? false;
  }

  @Getter()
  public get sliderAbcTest(): string {
    return this._root.settings?.news_slider ?? 'A';
  }

  @Getter()
  public get userLoyaltySegment(): LoyaltyRole | null {
    return this.loyaltySegment;
  }

  @Getter()
  public get staticContent(): string {
    return (this._root?.data?.data?.static_content?.data as string) || '';
  }

  @Getter()
  public get isViqeoCallbacksLoaded(): boolean {
    return this.viqeoCallbacksLoaded;
  }

  // @TODO перенести в user.module.ts все что связано с пользователем (?)
  @Action()
  public async checkAuth(norequest = false): Promise<void> {
    const hasTtq = document.cookie.includes('ngs_ttq');

    if (!hasTtq) {
      return;
    }

    if (!norequest) {
      const userInfo = await this._authDataMapper.checkAuthorization(this._root.regionId);
      this.setUserInfo(userInfo as UserInfo | null);
      void this.fetchNewProfileNotices();

      if (userInfo !== null) {
        this.runUserStatusSocket(userInfo.userId);
      } else {
        this.userModerationStatusService.closeWS();
      }
    }

    this.sendUserAuthMetric();
    this.setAuthChecked(true);
  }

  @Action()
  public async logout(): Promise<void> {
    await this._authDataMapper.logout(this._root.regionId);

    this.setUserAuthStateToLocalStorage(false);
  }

  // хак для UMP, тк UMP ходит за профилем только если UMP_USER_IS_AUTH == true, то нужно засетить этот ключик, если авторизовываемся/выходим на старом паблике
  @Action()
  public setUserAuthStateToLocalStorage(status: boolean): void {
    const LOCAL_STORAGE_USER_IS_AUTH_KEY = 'UMP_USER_IS_AUTH';

    localStorageApiFactory(localStorage).set<boolean>(
      LOCAL_STORAGE_USER_IS_AUTH_KEY,
      status
    );
  }

  @Action()
  public async checkAuthOnForumNN(): Promise<void> {
    if (isSameRegion(this._root.regionId, Regions.NizhnyNovgorod)) {
      const data = await this._authDataMapper.checkAuthOnForumNN();

      this.setForumProfile(data);
      this.setAuthForumChecked(true);
      this.sendNNForumUserAuthMetric();
      void this.checkSpWidgetNotify();
    }
  }

  @Action()
  public async logoutFromForumNN(): Promise<void> {
    await this._authDataMapper.logoutFromForumNN();
    this.setForumProfile(null);
  }

  @Action()
  public sendUserAuthMetric(): void {
    const passportId = this.userInfo?.userId ?? 0;
    const authStatus = !guardUnspecified(this.userInfo)
      ? 'Неавторизованный'
      : {
          ['Авторизованный']: {
            [this.userInfo.hasProfile ? 'С_профилем' : 'Без_профиля']:
              passportId.toString()
          }
        };

    this.sendAuthReachGoal(authStatus);
  }

  @Action()
  public sendNNForumUserAuthMetric(): void {
    const authStatus = this.forumProfileData?.passportId
      ? { ['Авторизованный на форуме']: this.forumProfileData.passportId.toString() }
      : 'Неавторизованный на форуме';

    this.sendAuthReachGoal(authStatus);
  }

  @Action()
  public sendAuthReachGoal(
    authStatus: string | Record<string, string | GoalValueParams>
  ): void {
    const name = 'userMetricsAuthorization';
    const params = {
      ['Пользователь']: { ['Авторизация']: authStatus }
    } as unknown as ValueParams;

    this._root.analyticsModule.sendReachGoal({ name, params });
  }

  @Action()
  public async fetchUserUid(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const uidData = (await this._root.newsApiClient.getUserUid(this._root.regionId)) as {
      data: { uid: string };
    };
    const uid = uidData?.data.uid ?? '';
    this.setUserUid(uid);
  }

  @Action()
  public async fetchNewProfileNotices(): Promise<void> {
    if (this.userInfo !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const result = (await this._root.newsApiClient.getNewNotices(
        this.userInfo.userId,
        this._root.regionId
      )) as number;

      this.setNewProfileNotices(result);
    }
  }

  @Action()
  public async checkSpWidgetNotify(): Promise<void> {
    if (this.forumProfileData !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
      const spWidgetNotify = await this._root.newsApiClient.getSpWidgetNotifyNN();
      this.setSpWidgetNotify(spWidgetNotify as boolean);
    }
  }

  @Action()
  startCollectPerformanceMetrics(): void {
    this._performanceMetricsService.collectPerformanceMetrics();
  }

  @Action()
  sendPagePerformanceMetrics(): void {
    const metrics = this._performanceMetricsService.performanceMetrics;
    this._root.analyticsModule.sendPerformanceMetrics(metrics);
  }

  @Action()
  async executeCaptchaToken(action: string): Promise<string> {
    if (
      guardUnspecified(this.captchaSettings) &&
      !guardUnspecified(this._captchaService)
    ) {
      const { publicKey, scriptSrc, type } = this.captchaSettings;

      if (guardEmptyString(publicKey) && guardEmptyString(scriptSrc)) {
        this._captchaService = new CaptchaService(
          type === 'recaptcha'
            ? new GReCaptchaService(publicKey)
            : new HCaptchaService(publicKey, 'captchaWrap')
        );
      }
    }

    if (!guardUnspecified(this.captchaSettings)) {
      return CaptchaService.getFallbackToken();
    }

    const isRecaptcha = this.captchaSettings.type === 'recaptcha';
    const fallbackToken = isRecaptcha ? CaptchaService.getFallbackToken() : '';

    try {
      if (this._root.pageType !== PagesType.Subscription) {
        await this._captchaService.prepare(this.captchaSettings);
      }

      const token = await this._captchaService.execute(action, isRecaptcha);
      return guardUnspecified(token) ? token : fallbackToken;
    } catch (e) {
      return fallbackToken;
    }
  }

  @Action()
  public updateViqeoCallbacksState(value: boolean): void {
    this.setViqeoCallbacksState(value);
  }

  @Mutation()
  public setAuthChecked(value: boolean): void {
    this.authUserChecked = value;
  }

  @Mutation()
  public setAuthForumChecked(value: boolean): void {
    this.authForumChecked = value;
  }

  @Mutation()
  public setUserInfo(value: UserInfo | null): void {
    this.userInfo = value;
  }

  @Mutation()
  public setForumProfile(value: ForumProfileData): void {
    this.forumProfileData = value;
  }

  @Mutation()
  public setUserUid(value: string): void {
    this.userUid = value;
  }

  @Mutation()
  public setUpdatingUserInfo(value: UserInfoDataFromProfile): void {
    if (guardUnspecified(value) && guardUnspecified(this.userInfo)) {
      const avatarData = getResponsiveImageData({
        url: value.avatar,
        width: 36,
        aspectRatio: ImageAspectRatio.Square,
        values: [
          {
            width: 36,
            breakpoint: 0,
            noMedia: true
          }
        ]
      });

      this.userInfo.avatar = value.avatar;
      this.userInfo.avatarData = {
        src: avatarData.data.src,
        sources: avatarData.data.sources
      };
      this.userInfo.login = value.nick;
      this.userInfo.sex = value.sex;
      this.userInfo.hasProfile = true;
      this.userInfo.isTrusted = value.trusted;
    } else {
      this.userInfo = null;
    }
  }

  @Mutation()
  public setNewProfileNotices(total: number): void {
    this.newProfileNotices = total;
  }

  @Mutation()
  public setSpWidgetNotify(data: boolean): void {
    this.hasSpNotify = data;
  }

  @Mutation()
  public emitHeaderServiceState(data: StickyHeaderState): void {
    this.headerService.changeState(data);
  }

  @Mutation()
  public emitSearchBarState(data: SearchBarState): void {
    this.searchBarService.changeState(data);
  }

  @Mutation()
  public updateMenuState(state: MenuState): void {
    this.menuService.changeState(state);
  }

  @Mutation()
  public updateAlertsState(state: AlertsState): void {
    this.alertsService.changeState(state);
  }

  @Mutation()
  public updateAlertsClosingAllowedState(state: boolean): void {
    this.alertsService.changeСlosingAllowedState(state);
  }

  @Mutation()
  public updateAlertsReadingStatus(state: boolean): void {
    this.alertsService.changeReadingStatus(state);
  }

  @Mutation()
  public updateUserMenuState(state: UserMenuState): void {
    this.userMenuService.changeState(state);
  }

  @Mutation()
  public updateUserForumsState(state: UserForumsState): void {
    this.userForumsService.changeState(state);
  }

  @Mutation()
  public updateUserLoyalty(state: LoyaltyRole): void {
    this.loyaltySegment = state;
  }

  @Mutation()
  public setViqeoCallbacksState(state: boolean): void {
    this.viqeoCallbacksLoaded = state;
  }

  public runUserStatusSocket(id: number): void {
    if (!this._hasSubscribeUserLockStatus) {
      const wsParams = `channelId=${id}`;
      this._hasSubscribeUserLockStatus = true;

      this.userModerationStatusService.startWS(this._root.envType, wsParams);
      this._subscribeUserLockStatus();
    }
  }

  public initCaptchaService(): void {
    if (guardUnspecified(this.captchaSettings)) {
      const { publicKey, scriptSrc, type } = this.captchaSettings;
      if (guardEmptyString(publicKey) && guardEmptyString(scriptSrc)) {
        this._captchaService = new CaptchaService(
          type === 'recaptcha'
            ? new GReCaptchaService(publicKey)
            : new HCaptchaService(publicKey, 'captchaWrap')
        );

        if (this._root.pageType === PagesType.Subscription) {
          void this._captchaService.prepare({ publicKey, scriptSrc, timeout: 3500 });
        }
      }
    }
  }

  // eslint-disable-next-line
  private _initLoyalty(): void {
    const isUserRegion = isRegionIdMatchedWithGeoId(
      this._root.regionId,
      this._root.geoRegionId
    );
    const loyaltyService = new LoyaltyService(isUserRegion, this._root.serverDate);

    setTimeout(() => {
      const { loyalty, daysFromLastVisit } = loyaltyService;

      const name = 'userLoyaltyGroups';
      const params = {
        ['Пользователь']: {
          ['Лояльность']: { [loyalty]: daysFromLastVisit.toString() }
        }
      } as unknown as ValueParams;

      this._root.analyticsModule.sendReachGoal({ name, params });

      this.updateUserLoyalty(loyalty);
    });
  }

  // eslint-disable-next-line
  private _sendReachGoalUserRegion(): void {
    const name = 'userRegion';
    const params = {
      ['Пользователь']: { ['Регион']: this._root.geoRegionId.toString() }
    };
    this._root.analyticsModule.sendReachGoal({ name, params });
  }

  // eslint-disable-next-line
  private _subscribeUserLockStatus(): void {
    this.userModerationStatusService.message$.subscribe(data => {
      if (data?.profileId > 0) {
        void this.checkAuth();
      }
    });
  }
}
